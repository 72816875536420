import { useMemo } from "react";
import { useBetslip, useDisplayAtLeast, useSelector } from "hooks";
import { use100vh } from "react-div-100vh";
import { useGetActiveFooterType } from "./useGetActiveFooterType";
import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";
import { useHeaderOffset } from "hooks/useHeaderOffset";

const TABS_HEIGHT = 60;
const SMALL_FOOTER_HEIGHT = 75;
const LARGE_FOOTER_HEIGHT = 125;

const useBetslipHeight = () => {
  const {
    props: { betslipIsReviewingMessages },
  } = useBetslip();
  const fullHeight = use100vh();
  const isMinDesktop = useDisplayAtLeast("desktop");
  const { betsFooter, betslipFooter } = useGetActiveFooterType();
  const playerOffset = useCalculatePlayerOffset();

  const topOffsetWithoutNav = useHeaderOffset();
  const topOffsetWithNav =
    topOffsetWithoutNav +
    useSelector((state) => state.utilities.ui.navigationHeaderHeight);

  const headerOffset = useMemo(
    () => (isMinDesktop ? topOffsetWithNav : topOffsetWithoutNav),
    [isMinDesktop, topOffsetWithoutNav, topOffsetWithNav],
  );

  return useMemo(() => {
    // - when we are in desktop mode, betslip should be 100% minus header + tabs
    // - when we are in mobile mode, we are not showing header, so we need to account for that
    // - when media player is opened it's accounted for in headerOffset
    const baseHeight = (fullHeight || 0) - TABS_HEIGHT - (headerOffset || 0);

    // when we are on bets & entries tab OR reviewing messages, we display small footer
    // we need to reduce betslip height by additional number of pixels
    if (betsFooter || betslipIsReviewingMessages) {
      return baseHeight - SMALL_FOOTER_HEIGHT;
    }

    // when we are showing full sized footer, we need to reduce betslip size as well
    if (betslipFooter) {
      // betslip footer is larger than bets footer
      return baseHeight - LARGE_FOOTER_HEIGHT;
    }

    return baseHeight;
  }, [
    fullHeight,
    betsFooter,
    betslipFooter,
    isMinDesktop,
    betslipIsReviewingMessages,
    playerOffset,
    headerOffset,
  ]);
};

export { useBetslipHeight };
